import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/foody/1.jpg";
import im2 from "../../Assets/foody/2.jpg";
import im3 from "../../Assets/foody/3.jpg";
import im4 from "../../Assets/foody/4.jpg";
import im5 from "../../Assets/foody/5.jpg";
import im6 from "../../Assets/foody/6.jpg";
import im7 from "../../Assets/foody/7.jpg";
import im8 from "../../Assets/foody/8.jpg";
import im9 from "../../Assets/foody/9.jpg";
import im10 from "../../Assets/foody/10.jpg";
import im11 from "../../Assets/foody/11.jpg";
import im12 from "../../Assets/foody/12.jpg";
import im13 from "../../Assets/foody/13.jpg";
import im14 from "../../Assets/foody/14.jpg";
import im15 from "../../Assets/foody/15.jpg";

function Foody() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          Foo<strong className="purple">dy </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          App is exclusively designed for users seeking to place food orders. It
          encompasses the following technical elements:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Front-end development implemented through React Native.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Back-end functionality powered by PHP.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Data management and storage facilitated by MySQL.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application offers a range of essential features to ensure a
          seamless and secure user experience:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Robust
          mechanisms for user identification and verification.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Account Management:</strong>{" "}
          Registration, login, logout, and account deletion functionalities.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Food and Restaurant Exploration:</strong>{" "}
          Effortless browsing and advanced filtering options for food items and
          restaurants.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Shopping Cart Integration:</strong> Users
          can add desired food items to their cart for convenient ordering.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Order Placement:</strong> A
          straightforward process for placing orders.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Online Payment:</strong> Secure payment
          processing for orders, featuring Stripe integration.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Rating and Review System:</strong> A
          star-based system for assessing and reviewing restaurants, enhancing
          the user feedback experience.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im7);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im8}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im8);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im9}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im9);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im10}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im10);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im11}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im11);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im12}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im12);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im13}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im13);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im14}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im14);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im15}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im15);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Foody;
