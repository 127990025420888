import React from "react";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";

import { ImRadioChecked } from "react-icons/im";

function ServicesCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            
          Explore our range of services to identify the one that aligns perfectly with your business needs.
          </p>
          <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
            <Col xs={4} md={4} className="tech-desc">
            <p style={{ textAlign: "justify" }}>Web Development</p>
            </Col>
            <Col xs={4} md={4} className="tech-desc">
            <p style={{ textAlign: "justify", fontSiz:20 }}>Mobile Apps Development</p>
            </Col>
            <Col xs={4} md={4} className="tech-desc">
            <p style={{ textAlign: "justify" }}>Custom Development</p>
            </Col>
           
           
          </Row>
         
          <p style={{ color: "rgb(155 126 172)" }}>
            "Design is not just what it looks like and feels like. Design is how it works."{" "}
          </p>
          <footer className="blockquote-footer">Steve Jobs</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default ServicesCard;
