import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BsGlobe } from "react-icons/bs";
import { Link } from "react-router-dom";

function ImageCard(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} onClick={props.onSelect} />
      
    </Card>
  );
}
export default ImageCard;
