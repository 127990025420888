import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/ecommerce/1.jpg";
import im2 from "../../Assets/ecommerce/2.jpg";
import im3 from "../../Assets/ecommerce/3.jpg";
import im4 from "../../Assets/ecommerce/4.jpg";
import im5 from "../../Assets/ecommerce/5.jpg";
import im6 from "../../Assets/ecommerce/6.jpg";

function Ecommerce() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}

        <h1 className="project-heading left">
          Ecom<strong className="purple">merce </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This web application is meticulously crafted using cutting-edge
          technologies:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Front-End Development:</strong> Powered
          by React.js, the web application delivers a highly responsive and
          user-friendly interface, ensuring a seamless user experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Database and Backend:</strong> Built on
          Firebase, the application benefits from the scalability, security, and
          real-time capabilities of this robust cloud-based platform.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Design Aesthetics:</strong> The user
          interface is thoughtfully designed using SCSS, ensuring an appealing
          and visually pleasing user experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The web application offers the complete range of functionalities
          expected from a comprehensive ecommerce platform:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Product Catalog:</strong> A comprehensive
          showcase of products for users to browse and explore.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • User Registration and Authentication:
          </strong>{" "}
          Secure mechanisms for users to create accounts and authenticate their
          identities.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Shopping Cart:</strong> A feature-rich
          cart system for users to add, modify, and manage their selected items.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Checkout and Payment:</strong> Seamless
          and secure payment processing to finalize transactions.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Order Tracking:</strong> Real-time order
          status updates for users to monitor the progress of their orders.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Product Reviews and Ratings:</strong>{" "}
          User-driven feedback and rating system to assist in decision-making.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Search and Filtering:</strong> Robust
          search and filtering options for finding specific products.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Profiles:</strong> Customizable user
          profiles with options for viewing and managing orders.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Notifications:</strong> Keeping users
          informed about order status, promotions, and updates.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Responsive Design:</strong> Ensuring
          compatibility across a range of devices and screen sizes for a
          consistent user experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The web application embodies the essence of a comprehensive ecommerce
          platform, offering a sophisticated and secure environment for users to
          explore, shop, and transact with confidence.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Ecommerce;
