import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/transilvania/1.jpg";
import im2 from "../../Assets/transilvania/2.jpg";
import im3 from "../../Assets/transilvania/3.jpg";
import im4 from "../../Assets/transilvania/4.jpg";
import im5 from "../../Assets/transilvania/5.jpg";
import im6 from "../../Assets/transilvania/6.jpg";
import im7 from "../../Assets/transilvania/7.jpg";
import im8 from "../../Assets/transilvania/8.jpg";
import im9 from "../../Assets/transilvania/9.jpg";

function Transilvania() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  return (
    <Container fluid className="project-section">
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}

        <h1 className="project-heading left">
          Transilvania<strong className="purple"> Design </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This website serves as an elegant presentation platform for a design
          company, showcasing a diverse range of design patterns collections.
          The website is meticulously crafted using the versatile WordPress
          content management system.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key features of the website include:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Design Patterns Collections:</strong>{" "}
          Visitors can explore a curated selection of designs collections,
          offering an immersive and visually engaging experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Designer's Portfolio:</strong> Providing
          insight into the designer's artistic vision, style, and craftsmanship.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• WordPress:</strong> Leveraging the robust
          capabilities of the WordPress platform for user-friendly content
          management and seamless presentation.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          This website is a testament to the artistry and creativity of the
          design company, offering an exquisite and accessible platform for
          showcasing their work.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im7);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im8}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im8);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im9}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im9);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Transilvania;
