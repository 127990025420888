import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function FoodyRestaurants() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading left">
          Foody <strong className="purple">Restaurants </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          Application is exclusively tailored to meet the needs of restaurants
          looking to expand their online presence and offer food sales. It
          incorporates the following technical components:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Utilizes React.js for the front-end development.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Employs PHP as the robust back-end framework.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Relies on MySQL for efficient database management.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application is equipped with a range of essential features aimed
          at providing restaurants with a comprehensive online food selling
          platform:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Ensures
          secure and controlled access for authorized users.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Food Management:</strong> Allows
          restaurants to add, delete, and modify food items and their respective
          prices, maintaining menu flexibility.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Image Upload:</strong> Supports the
          addition of appealing visuals to enhance the presentation of food
          items.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Order Monitoring:</strong> Provides
          real-time order tracking, allowing restaurants to efficiently manage
          and fulfill orders.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Delivery Integration:</strong>{" "}
          Streamlines the delivery process, enabling timely and efficient order
          fulfillment.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application delivers an all-encompassing solution for restaurants
          to establish and manage their online food sales operations
          effectively, enhancing their market presence and customer reach.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default FoodyRestaurants;
