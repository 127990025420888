import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BsGlobe } from "react-icons/bs";
import { Link } from "react-router-dom";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>

        {"\n"}
        {"\n"}
        <Link to={props.demoLink}>
          <Button variant="primary" >
            <BsGlobe /> &nbsp;
            {"Details"}
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
