import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Rents() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading left">
          Ren<strong className="purple">ts </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This application serves two distinct user segments, addressing the
          needs of property owners seeking to lease their properties and
          individuals in search of rental accommodations. It boasts the
          following essential technical features:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Front-End Development:</strong> The user
          interface is developed using React Native, ensuring an intuitive and
          responsive experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Back-End Framework:</strong> The
          application's core functionalities are powered by PHP, delivering
          robust server-side capabilities.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Data Management:</strong> Efficient data
          storage and management are facilitated through MySQL, ensuring data
          integrity and reliability.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Real-Time Communication:</strong> The
          application leverages the real-time capabilities of Node.js and
          Socket.io for seamless user interactions.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key Functionalities offered by the application encompass: • Robust
          User Authentication: Ensuring the privacy and security of user
          profiles and data.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Account Management:</strong>{" "}
          Allowing users to register, log in, log out, and delete their
          accounts, putting them in control of their experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Comprehensive Property Search:</strong>{" "}
          Facilitating effortless browsing and advanced filtering for rental
          listings, simplifying the property discovery process.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Listing Management:</strong> Empowering
          property owners to create, edit, and manage their rental listings with
          ease.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Real-Time Chat:</strong> Enabling instant
          messaging and communication between users, promoting seamless
          interactions.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• View Tracking:</strong> Providing users
          with insight into the popularity of their rental listings through
          real-time view monitoring.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Stripe Integration:</strong> Streamlining
          payment processing for rental posting transactions, ensuring a secure
          and efficient payment experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Image Upload Capability:</strong> Users
          can enhance the visual appeal of their property listings by uploading
          images, further enriching the browsing experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          This application represents a comprehensive solution, ensuring a
          secure, efficient, and user-centric platform for property owners and
          seekers to engage in the rental market.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Rents;
