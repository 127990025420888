import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/photosharing/1.jpg";
import im2 from "../../Assets/photosharing/2.jpg";
import m1 from "../../Assets/photosharing/m1.jpg";
import m2 from "../../Assets/photosharing/m2.jpg";

function PhotoSharing() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          Photo <strong className="purple">Sharing </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This web application has been developed using React.js, catering to a
          specific and valuable purpose: wedding photo sharing. The application
          is designed to facilitate seamless engagement and multimedia sharing
          among attendees at a wedding event.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key features of the application include:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• QR Code Integration:</strong> Attendees
          can effortlessly join the wedding event by scanning a QR code,
          providing instant access to the application.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Media Upload:</strong> Users can upload
          photos and videos, contributing to a collective repository of
          memorable moments and visual content from the event.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Admin Functionality:</strong>{" "}
          Administrators have the ability to manage and curate the content by
          deleting photos and videos, ensuring the quality and appropriateness
          of shared media.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          This application creates an interactive and memorable experience for
          wedding attendees, allowing them to capture and share precious
          moments, fostering a sense of community and connection during the
          event.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m2);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default PhotoSharing;
