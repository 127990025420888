import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Alergomed() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading left">
          Alergo<strong className="purple">med </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This website serves as an informative presentation platform for a
          company specializing in medical equipment. Designed and developed
          using the versatile WordPress platform, it offers valuable insights
          into the company's involvement in the medical equipment sector.
        </p>
        <p style={{ color: "white" }} className="left">
          {" "}
          Key features of the website include:
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Company Overview:</strong> Providing an
          in-depth understanding of the company's mission, values, and its role
          within the medical equipment industry.
        </p>
        <p style={{ color: "white" }} className="left">
          {" "}
          <strong className="purple">• Product Portfolio:</strong> Showcasing a
          range of medical equipment solutions, highlighting the diversity and
          quality of products offered.
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Contact Information:</strong> Offering
          visitors a convenient and accessible means to reach out to the company
          for inquiries, product information, or partnership opportunities.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          This website exemplifies the company's commitment to the medical
          equipment field, delivering a user-friendly and informative online
          presence.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Alergomed;
