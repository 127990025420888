import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/imolacy/1.jpg";
import im2 from "../../Assets/imolacy/2.jpg";
import im3 from "../../Assets/imolacy/3.jpg";
import im4 from "../../Assets/imolacy/4.jpg";
import im5 from "../../Assets/imolacy/5.jpg";
import im6 from "../../Assets/imolacy/6.jpg";
import m1 from "../../Assets/imolacy/m1.jpg";
import m2 from "../../Assets/imolacy/m2.jpg";
import m3 from "../../Assets/imolacy/m3.jpg";
import m4 from "../../Assets/imolacy/m4.jpg";
import m5 from "../../Assets/imolacy/m5.jpg";
import m6 from "../../Assets/imolacy/m6.jpg";
import m7 from "../../Assets/imolacy/m7.jpg";

function Imolacy() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          Imo<strong className="purple">lacy </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This website serves as an elegant presentation platform for a wedding
          dress designer, showcasing a diverse range of wedding dress
          collections. The website is meticulously crafted using the versatile
          WordPress content management system.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key features of the website include:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Wedding Dress Collections:</strong>{" "}
          Visitors can explore a curated selection of wedding dress collections,
          offering an immersive and visually engaging experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Designer's Portfolio:</strong> Providing
          insight into the designer's artistic vision, style, and craftsmanship.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• WordPress:</strong> Leveraging the robust
          capabilities of the WordPress platform for user-friendly content
          management and seamless presentation.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          This website is a testament to the artistry and creativity of the
          wedding dress designer, offering an exquisite and accessible platform
          for showcasing their work.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m5);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m6);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m7);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Imolacy;
