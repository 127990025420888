import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function GWWebApp() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading left">
          GW <strong className="purple">Web App </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          The application caters to two distinct user groups: individuals
          seeking to post job opportunities and those in search of job or
          project opportunities. The technical foundation of our application is
          as follows:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Front-end development is implemented using React.js, ensuring a
            modern and user-friendly interface.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • The database is powered by MySQL for secure and efficient data
            management.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • PHP serves as the robust back-end framework, while real-time
            communication between users is enabled by Node.js and Socket.io.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application boasts a comprehensive set of features to provide a
          seamless and effective experience for both job seekers and job
          posters:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Ensures
          secure access and data integrity for all users.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Account Management:</strong> Allows
          for user registration, login, logout, and account deletion.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Project Posting:</strong> Enables users
          to create project listings, specifying categories, uploading relevant
          images or files, setting budgets, and defining project timeframes.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Project Management:</strong> Provides
          tools for users to monitor project views and make necessary edits,
          including modification and deletion of posted projects.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Job Search and Application:</strong> Job
          seekers can filter projects based on various criteria, such as
          geolocation and budget, and submit applications, including the ability
          to upload files such as resumes.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Project Bookmarking:</strong> Allows
          users to save and archive projects for future reference.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Application Management:</strong> Users
          can manage their applications by deleting, modifying, or editing them.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Messaging System:</strong> Facilitates
          direct communication between project owners and job applicants,
          enhancing collaboration and interaction.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application is designed to provide a robust and user-centric
          platform for job seekers and job posters, promoting efficient
          job/project discovery and meaningful communication between the two
          user groups.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default GWWebApp;
