import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/getwork/1.jpg";
import im2 from "../../Assets/getwork/2.jpg";
import im3 from "../../Assets/getwork/3.jpg";
import im4 from "../../Assets/getwork/4.jpg";
import im5 from "../../Assets/getwork/5.jpg";
import im6 from "../../Assets/getwork/6.jpg";
import im7 from "../../Assets/getwork/7.jpg";
import im8 from "../../Assets/getwork/8.jpg";
import im9 from "../../Assets/getwork/9.jpg";
import im10 from "../../Assets/getwork/10.jpg";
import im11 from "../../Assets/getwork/11.jpg";
import im12 from "../../Assets/getwork/12.jpg";
import im13 from "../../Assets/getwork/13.jpg";
import im14 from "../../Assets/getwork/14.jpg";

function GWMobileApp() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          GW <strong className="purple">Mobile App </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          The application caters to two distinct user groups: individuals
          seeking to post job opportunities and those in search of job or
          project opportunities. The technical foundation of our application is
          as follows:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          {" "}
          <strong className="purple">
            • Front-end development is implemented using React Native, ensuring
            a modern and user-friendly interface.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • The database is powered by MySQL for secure and efficient data
            management.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • PHP serves as the robust back-end framework, while real-time
            communication between users is enabled by Node.js and Socket.io.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application boasts a comprehensive set of features to provide a
          seamless and effective experience for both job seekers and job
          posters:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Ensures
          secure access and data integrity for all users.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Account Management:</strong> Allows
          for user registration, login, logout, and account deletion.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Project Posting:</strong> Enables users
          to create project listings, specifying categories, uploading relevant
          images or files, setting budgets, and defining project timeframes.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Project Management:</strong> Provides
          tools for users to monitor project views and make necessary edits,
          including modification and deletion of posted projects.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Job Search and Application:</strong> Job
          seekers can filter projects based on various criteria, such as
          geolocation and budget, and submit applications, including the ability
          to upload files such as resumes.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Project Bookmarking:</strong> Allows
          users to save and archive projects for future reference.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Application Management:</strong> Users
          can manage their applications by deleting, modifying, or editing them.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Messaging System:</strong> Facilitates
          direct communication between project owners and job applicants,
          enhancing collaboration and interaction.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application is designed to provide a robust and user-centric
          platform for job seekers and job posters, promoting efficient
          job/project discovery and meaningful communication between the two
          user groups.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im7);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im8}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im8);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im9}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im9);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im10}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im10);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im11}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im11);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im12}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im12);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im13}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im13);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im14}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im14);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default GWMobileApp;
