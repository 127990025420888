import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/mdmobile/1.jpg";
import im2 from "../../Assets/mdmobile/2.jpg";
import im3 from "../../Assets/mdmobile/3.jpg";
import im4 from "../../Assets/mdmobile/4.jpg";
import im5 from "../../Assets/mdmobile/5.jpg";
import im6 from "../../Assets/mdmobile/6.jpg";
import im7 from "../../Assets/mdmobile/7.jpg";
import im8 from "../../Assets/mdmobile/8.jpg";
import im9 from "../../Assets/mdmobile/9.jpg";
import im10 from "../../Assets/mdmobile/10.jpg";
import im11 from "../../Assets/mdmobile/11.jpg";
import im12 from "../../Assets/mdmobile/12.jpg";
import im13 from "../../Assets/mdmobile/13.jpg";
import im14 from "../../Assets/mdmobile/14.jpg";
import im15 from "../../Assets/mdmobile/15.jpg";
import im16 from "../../Assets/mdmobile/16.jpg";
import im17 from "../../Assets/mdmobile/17.jpg";
import im18 from "../../Assets/mdmobile/18.jpg";
import im19 from "../../Assets/mdmobile/19.jpg";
import im20 from "../../Assets/mdmobile/20.jpg";
import im21 from "../../Assets/mdmobile/21.jpg";
import im22 from "../../Assets/mdmobile/22.jpg";
import im23 from "../../Assets/mdmobile/23.jpg";
import im24 from "../../Assets/mdmobile/24.jpg";
import im25 from "../../Assets/mdmobile/25.jpg";
import im26 from "../../Assets/mdmobile/26.jpg";
import im27 from "../../Assets/mdmobile/27.jpg";

function MdMobileApp() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          MD <strong className="purple">Mobile App </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          Application is dedicated to fostering online connections and
          facilitating the journey towards love and, potentially, marriage. It
          features a robust technical foundation:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Utilizes React Native for the front-end, ensuring a modern and
            user-friendly interface.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Employs MySQL for secure and efficient database management.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Leverages PHP for powerful back-end functionality.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Enables real-time communication through Socket.io, Node.js, and
            Express.js, enhancing user interaction.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application offers a rich array of functionalities, designed to
          create a seamless and engaging online dating experience:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Ensures the
          integrity and security of user accounts.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Account Management:</strong> Allows
          users to register, log in, log out, edit their profiles, and delete
          their accounts.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Personalization:</strong> Users can add
          their hobbies and interests, as well as upload profile photos and
          create photo galleries.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Profile Browsing:</strong> Offers a
          convenient way to explore and filter profiles to find potential
          matches.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Swipe Mechanism:</strong> Utilizes a
          Tinder-style swiping system for liking or disliking profiles.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Social Network Features:</strong> Users
          can add, remove, or block friends, enhancing their social interaction.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Matching System:</strong> Incorporates a
          likes/dislikes system to facilitate potential matches.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Settings:</strong> Includes age range
          filtering, geolocation filtering, and distance-based filtering to
          personalize user experiences.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Communication:</strong> Features audio
          and video calling capabilities for more intimate connections.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Notifications:</strong> Keeps users
          informed with push notifications.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Payment Integration:</strong> Facilitates
          premium services through a secure and reliable payment system.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application is dedicated to fostering meaningful connections,
          providing users with the tools and features to embark on their journey
          towards love and, ultimately, marriage.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im7);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im8}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im8);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im9}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im9);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im10}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im10);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im11}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im11);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im12}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im12);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im13}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im13);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im14}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im14);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im15}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im15);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im16}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im16);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im17}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im17);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im18}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im18);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im19}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im19);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im20}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im20);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im21}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im21);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im22}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im22);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im23}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im23);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im24}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im24);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im25}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im25);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im26}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im26);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im27}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im27);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default MdMobileApp;
