import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import ImageModal from "../ImageModal/ImageModal";
import im1 from "../../Assets/arax/1.jpg";
import im2 from "../../Assets/arax/2.jpg";
import im3 from "../../Assets/arax/3.jpg";
import im4 from "../../Assets/arax/4.jpg";
import im5 from "../../Assets/arax/5.jpg";
import im6 from "../../Assets/arax/6.jpg";
import im7 from "../../Assets/arax/7.jpg";
import im8 from "../../Assets/arax/8.jpg";
import im9 from "../../Assets/arax/9.jpg";
import m1 from "../../Assets/arax/m1.jpg";
import m2 from "../../Assets/arax/m2.jpg";
import m3 from "../../Assets/arax/m3.jpg";
import m4 from "../../Assets/arax/m4.jpg";
import m5 from "../../Assets/arax/m5.jpg";

function Arax() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}

        <h1 className="project-heading left">
          Ar<strong className="purple">ax </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          The website is meticulously developed using the Codeigniter framework,
          with PHP serving as the robust backend and MySQL as the database
          engine. This platform is tailored to offer a comprehensive investment
          experience, featuring a range of functionalities.
        </p>
        <p style={{ color: "white" }} className="left">
          Key functionalities include:
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong>{" "}
          Implementing secure user registration, login, and logout processes to
          protect user information and ensure data integrity.
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Investment Opportunities:</strong> Users
          can access a diverse array of investment products and projects,
          empowering them to make informed financial decisions and potentially
          grow their wealth.
        </p>
        <p style={{ color: "white" }} className="left">
          The website is dedicated to providing a user-friendly and secure
          environment for users to explore and participate in various investment
          opportunities, ultimately enabling them to work towards their
          financial goals.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im7);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im8}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im8);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im9}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im9);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m5);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Arax;
