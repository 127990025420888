import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import arax from "../../Assets/Projects/arax.png";
import imolacy from "../../Assets/Projects/imolacy.png";
import niox from "../../Assets/Projects/niox.png";
import greenenergy from "../../Assets/Projects/greenenergy.png";
import alergomed from "../../Assets/Projects/alergomed.png";
import photosharing from "../../Assets/Projects/photosharing.png";
import foody from "../../Assets/Projects/foody.png";
import mdmobile from "../../Assets/Projects/mdmobile.png";
import ecommerce from "../../Assets//Projects/ecommerce.png";
import transilvania from "../../Assets/Projects/transilvania.png";
import whatsapp from "../../Assets/Projects/whatsapp.png";
import xlevel from "../../Assets/Projects/xlevel.png";
import getwork from "../../Assets/Projects/getwork.png";
import wldmobile from "../../Assets/Projects/wldmobile.png";
import wld from "../../Assets/Projects/wld.png";
import md from "../../Assets/Projects/md.png";
import bam from "../../Assets/Projects/bam.png";

function Projects() {
  const [option, setOption] = useState("all");
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects we have worked on recently.
        </p>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              display: "flex",
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {option == "all" ? (
              <div>
                <div className="divselected">
                  <p className="selectedText">All</p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setOption("all");
                }}
              >
                <div className="divnotselected">
                  <p className="notselectedText">All</p>
                </div>
              </div>
            )}
            {option == "reactnative" ? (
              <div>
                <div className="divselected">
                  <p className="selectedText">React Native</p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setOption("reactnative");
                }}
              >
                <div className="divnotselected">
                  <p className="notselectedText">React Native</p>
                </div>
              </div>
            )}
            {option == "react" ? (
              <div>
                <div className="divselected">
                  <p className="selectedText">React</p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setOption("react");
                }}
              >
                <div className="divnotselected">
                  <p className="notselectedText">React</p>
                </div>
              </div>
            )}
            {option == "wordpress" ? (
              <div>
                <div className="divselected">
                  <p className="selectedText">Wordpress</p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setOption("wordpress");
                }}
              >
                <div className="divnotselected">
                  <p className="notselectedText">Wordpress</p>
                </div>
              </div>
            )}

            {option == "codeigniter" ? (
              <div>
                <div className="divselected">
                  <p className="selectedText">Code Igniter</p>
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setOption("codeigniter");
                }}
              >
                <div className="divnotselected">
                  <p className="notselectedText">Code Igniter</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {option == "all" || option == "wordpress" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={greenenergy}
                title="Green Energy"
                description="This website serves as a compelling digital presentation for a company dedicated to advancing the cause of green energy. Developed using the versatile WordPress platform, it showcases the company's commitment to sustainability and renewable energy solutions."
                demoLink="/green-energy"
              />
            </Col>
          ) : null}

          {option == "all" || option == "react" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={photosharing}
                title="Photo Sharing"
                description="This web application has been developed using React.js, catering to a specific and valuable purpose: wedding photo sharing. The application is designed to facilitate seamless engagement and multimedia sharing among attendees at a wedding event."
                demoLink="/photo-sharing"
              />
            </Col>
          ) : null}

          {option == "all" || option == "react" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={ecommerce}
                title="Ecommerce Prototype"
                description="This web application is meticulously crafted using cutting-edge technologies: Front-End Development: Powered by React.js, the web application delivers a highly responsive and user-friendly interface, ensuring a seamless user experience; Database and Backend: Built on Firebase, the application benefits from the scalability, security, and real-time capabilities of this robust cloud-based platform.	Design Aesthetics: The user interface is thoughtfully designed using SCSS, ensuring an appealing and visually pleasing user experience."
                demoLink="/ecommerce"
              />
            </Col>
          ) : null}

          {option == "all" || option == "wordpress" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={niox}
                title="Niox"
                description="This website serves as an informative presentation platform for a company specializing in medical equipment. Designed and developed using the versatile WordPress platform, it offers valuable insights into the company's involvement in the medical equipment sector."
                demoLink="/niox"
              />
            </Col>
          ) : null}

          {option == "all" || option == "codeigniter" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={arax}
                title="Arax"
                description="The website is meticulously developed using the Codeigniter framework, with PHP serving as the robust backend and MySQL as the database engine. This platform is tailored to offer a comprehensive investment experience, featuring a range of functionalities."
                demoLink="/arax"
              />
            </Col>
          ) : null}

          {option == "all" || option == "wordpress" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={imolacy}
                title="Imolacy"
                description="This website serves as an elegant presentation platform for a wedding dress designer, showcasing a diverse range of wedding dress collections. The website is meticulously crafted using the versatile WordPress content management system."
                demoLink="/imolacy"
              />
            </Col>
          ) : null}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imolacy}
              title="Rents"
              description="This application serves two distinct user segments, addressing the needs of property owners seeking to lease their properties and individuals in search of rental accommodations."
              demoLink="/rents"
            />
          </Col> */}

          {option == "all" || option == "reactnative" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={getwork}
                title="Get Work Mobile App"
                description="The application caters to two distinct user groups: individuals seeking to post job opportunities and those in search of job or project opportunities. The technical foundation of our application is as follows: Front-end development is implemented using React Native, ensuring a modern and user-friendly interface; The database is powered by MySQL for secure and efficient data management; PHP serves as the robust back-end framework, while real-time communication between users is enabled by Node.js and Socket.io."
                demoLink="/gw-mobile"
              />
            </Col>
          ) : null}

          {option == "all" || option == "reactnative" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={foody}
                title="Foody"
                description="App is exclusively designed for users seeking to place food orders. It encompasses the following technical elements: Front-end development implemented through React Native; Back-end functionality powered by PHP; Data management and storage facilitated by MySQL."
                demoLink="/foody"
              />
            </Col>
          ) : null}

          {option == "all" || option == "reactnative" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={mdmobile}
                title="MD Mobile App"
                description="Application is dedicated to fostering online connections and facilitating the journey towards love and, potentially, marriage. It features a robust technical foundation: Utilizes React Native for the front-end, ensuring a modern and user-friendly interface; Employs MySQL for secure and efficient database management; Leverages PHP for powerful back-end functionality; Enables real-time communication through Socket.io, Node.js, and Express.js, enhancing user interaction."
                demoLink="/md-mobileapp"
              />
            </Col>
          ) : null}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imolacy}
              title="Foody Restaurants"
              description="Application is exclusively tailored to meet the needs of restaurants looking to expand their online presence and offer food sales. It incorporates the following technical components: Utilizes React.js for the front-end development; Employs PHP as the robust back-end framework; Relies on MySQL for efficient database management.
              "
              demoLink="/foody-restaurants"
            />
          </Col> */}

          {option == "all" || option == "react" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={md}
                title="MD Web App"
                description="Application is dedicated to fostering online connections and facilitating the journey towards love and, potentially, marriage. It features a robust technical foundation: Utilizes React.js for the front-end, ensuring a modern and user-friendly interface; Employs MySQL for secure and efficient database management; Leverages PHP for powerful back-end functionality; Enables real-time communication through Socket.io, Node.js, and Express.js, enhancing user interaction."
                demoLink="/md-webapp"
              />
            </Col>
          ) : null}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imolacy}
              title="Get Work Web App"
              description="The application caters to two distinct user groups: individuals seeking to post job opportunities and those in search of job or project opportunities. The technical foundation of our application is as follows: Front-end development is implemented using React.js, ensuring a modern and user-friendly interface; The database is powered by MySQL for secure and efficient data management; PHP serves as the robust back-end framework, while real-time communication between users is enabled by Node.js and Socket.io."
              demoLink="/gw-web"
            />
          </Col> */}

          {option == "all" || option == "wordpress" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={alergomed}
                title="Alergomed"
                description="This website serves as an informative presentation platform for a company specializing in medical equipment. Designed and developed using the versatile WordPress platform, it offers valuable insights into the company's involvement in the medical equipment sector."
                demoLink="/alergomed"
              />
            </Col>
          ) : null}

          {option == "all" || option == "wordpress" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={transilvania}
                title="Transilvania Design"
                description="This website serves as an informative presentation platform for a company specializing in design. Designed and developed using the versatile WordPress platform, it offers valuable insights into the company's involvement in the design sector."
                demoLink="/transilvania-design"
              />
            </Col>
          ) : null}

          {option == "all" || option == "react" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={whatsapp}
                title="WhatsApp Clone"
                description="This web application has been meticulously developed, utilizing state-of-the-art technologies: Front-End Development: Employing React.js, the web application delivers a highly responsive and user-friendly interface, ensuring a seamless and intuitive user experience; Database and Backend: Powered by Firebase, the application leverages this cloud-based platform for robust data management and backend functionalities."
                demoLink="/whatsapp"
              />
            </Col>
          ) : null}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imolacy}
              title="Clipz"
              description="The web application is meticulously crafted using Angular and relies on Firebase for both its database and backend functionality. It offers a versatile platform where each user can seamlessly: User Authentication: Securely log in to their accounts, ensuring a personalized and protected experience; Video Upload: Users can effortlessly upload video clips to their accounts, contributing to a diverse collection of multimedia content; Video Viewing and Browsing: Enables other users to explore, view, filter, and browse these video clips, fostering an engaging and interactive environment."
              demoLink="/clipz"
            />
          </Col> */}

          {option == "all" || option == "reactnative" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={wldmobile}
                title="WLD Mobile App"
                description="Application is dedicated to fostering online connections and facilitating the journey towards love and, potentially, marriage. It features a robust technical foundation: Utilizes React Native for the front-end, ensuring a modern and user-friendly interface; Employs MySQL for secure and efficient database management; Leverages PHP for powerful back-end functionality; Enables real-time communication through Socket.io, Node.js, and Express.js, enhancing user interaction."
                demoLink="/wld-mobile"
              />
            </Col>
          ) : null}

          {option == "all" || option == "react" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={wld}
                title="WLD Web App"
                description="Application is dedicated to fostering online connections and facilitating the journey towards love and, potentially, marriage. It features a robust technical foundation: Utilizes React.js for the front-end, ensuring a modern and user-friendly interface; Employs MySQL for secure and efficient database management; Leverages PHP for powerful back-end functionality; Enables real-time communication through Socket.io, Node.js, and Express.js, enhancing user interaction."
                demoLink="/wld-web"
              />
            </Col>
          ) : null}

          {option == "all" || option == "react" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={bam}
                title="BAM Assets Management"
                description="Application  is a comprehensive web application designed to help individuals manage their assets effectively and plan for their financial future. Whether you're saving for retirement, funding your children's education, or simply seeking to grow your wealth, BAM  provides the tools and resources you need to achieve your financial goals."
                demoLink="/bam"
              />
            </Col>
          ) : null}

          {option == "all" || option == "wordpress" ? (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={xlevel}
                title="XLevel Fitness"
                description="This website serves as an informative presentation platform for a company specializing in fitness & bodybuilding. Designed and developed using the versatile WordPress platform, it offers valuable insights into the company's involvement in the fitness sector."
                demoLink="/xlevel-fitness"
              />
            </Col>
          ) : null}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
