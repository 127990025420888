import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/xlevel/1.jpg";
import im2 from "../../Assets/xlevel/2.jpg";
import im3 from "../../Assets/xlevel/3.jpg";
import im4 from "../../Assets/xlevel/4.jpg";
import im5 from "../../Assets/xlevel/5.jpg";
import im6 from "../../Assets/xlevel/6.jpg";
import m2 from "../../Assets/xlevel/m2.jpg";
import m3 from "../../Assets/xlevel/m3.jpg";
import m4 from "../../Assets/xlevel/m4.jpg";
import m5 from "../../Assets/xlevel/m5.jpg";

function Xlevel() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          XLevel<strong className="purple"> Fitness </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This website serves as an elegant presentation platform for a fitness
          & bodybuilding personal trainer, showcasing a diverse range of
          exercises & techniques. The website is meticulously crafted using the
          versatile WordPress content management system.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key features of the website include:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Gym Exercises:</strong> Visitors can
          explore a curated selection of gym exercises, offering an immersive
          and visually engaging experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Photo Gallery:</strong> Providing insight
          into the gym's machines.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• WordPress:</strong> Leveraging the robust
          capabilities of the WordPress platform for user-friendly content
          management and seamless presentation.{" "}
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m3);
              }}
            />
          </Col>
        </Row>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m5);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Xlevel;
