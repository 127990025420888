import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Clipz() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading left">
          Cli<strong className="purple">pz </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          The web application is meticulously crafted using Angular and relies
          on Firebase for both its database and backend functionality.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          It offers a versatile platform where each user can seamlessly:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Securely
          log in to their accounts, ensuring a personalized and protected
          experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Video Upload:</strong> Users can
          effortlessly upload video clips to their accounts, contributing to a
          diverse collection of multimedia content.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Video Viewing and Browsing:</strong>{" "}
          Enables other users to explore, view, filter, and browse these video
          clips, fostering an engaging and interactive environment.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application provides a user-centric space where multimedia content
          is shared, enjoyed, and accessed, offering a seamless and intuitive
          experience for all participants
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>

          <Col md={4} className="project-card"></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Clipz;
