import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/bam/1.png";
import im2 from "../../Assets/bam/2.png";
import im3 from "../../Assets/bam/3.png";
import im4 from "../../Assets/bam/4.png";
import im5 from "../../Assets/bam/5.png";
import im6 from "../../Assets/bam/6.png";
import im7 from "../../Assets/bam/7.png";
import im8 from "../../Assets/bam/8.png";
import im9 from "../../Assets/bam/9.png";
import im10 from "../../Assets/bam/10.png";
import im11 from "../../Assets/bam/11.png";
import im12 from "../../Assets/bam/12.png";
import im13 from "../../Assets/bam/13.png";
import im14 from "../../Assets/bam/14.png";
import im15 from "../../Assets/bam/15.png";

function Bam() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          BAM Assets<strong className="purple"> Management </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This website is a comprehensive web application designed to help
          individuals manage their assets effectively and plan for their
          financial future. Whether you're saving for retirement, funding your
          children's education, or simply seeking to grow your wealth, the app
          provides the tools and resources you need to achieve your financial
          goals.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key features of the website include:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Pension Calculator:</strong> The pension
          calculator allows users to estimate the amount of money they will need
          to maintain their desired lifestyle during retirement.Users can input
          various factors such as current age, retirement age, desired
          retirement income, expected rate of return on investments, and life
          expectancy to generate personalized retirement savings projections.The
          calculator provides actionable insights and recommendations to help
          users optimize their retirement savings strategy and achieve their
          retirement goals.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Education Funding Calculator:</strong>{" "}
          The education funding calculator helps users estimate the cost of
          their children's education and determine how much they need to save
          each month to achieve their education savings goals.Users can input
          parameters such as the number of children, their current ages,
          expected college expenses, anticipated inflation rate, and investment
          timeframe to generate a customized savings plan.{" "}
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im6);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im7}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im7);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im8}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im8);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im9}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im9);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im10}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im10);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im11}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im11);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im12}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im12);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im13}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im13);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im14}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im14);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im15}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im15);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Bam;
