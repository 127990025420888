import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Industry-focused <span className="purple"> App Development </span>
            </h1>
            <p className="home-about-body">
              Through the development of industry-specific applications,
              businesses can enhance their operational efficiency, decrease
              expenses, and secure a competitive edge within the market.
            </p>
            <h1 style={{ fontSize: "2.6em" }}>
              Applications that Enhance{" "}
              <span className="purple"> Business Performance. </span>
            </h1>
            <p className="home-about-body">
              Empower Your Business with Industry-Specific Apps, Enhancing
              Efficiency, Reducing Costs, and Bolstering Your Competitive Edge
              in the Market.
              <br />
              <b className="purple">We specialize in:</b>
              <br />
              <i>
                <b className="purple">Web Apps</b>
                <br />
                <b className="purple">Mobile Apps</b>
                <br />
                <b className="purple">Cross-Platform Apps</b>
              </i>
              <br />
              <br />
              Let us craft a powerful &nbsp;
              <i>
                <b className="purple">
                  Business Performance Management Software solution tailored to
                  your needs.{" "}
                </b>
              </i>
              <br />
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
}
export default Home2;
