import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import ge1 from "../../Assets/green-energy/1.jpg";
import ge2 from "../../Assets/green-energy/2.jpg";
import ge3 from "../../Assets/green-energy/3.jpg";
import ge4 from "../../Assets/green-energy/4.jpg";
import ge5 from "../../Assets/green-energy/5.jpg";
import ge6 from "../../Assets/green-energy/6.jpg";
import m1 from "../../Assets/green-energy/m1.jpg";
import m2 from "../../Assets/green-energy/m2.jpg";
import m3 from "../../Assets/green-energy/m3.jpg";
import m4 from "../../Assets/green-energy/m4.jpg";

function GreenEnergy() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          Green <strong className="purple">Energy </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This website serves as a compelling digital presentation for a company
          dedicated to advancing the cause of green energy. Developed using the
          versatile WordPress platform, it showcases the company's commitment to
          sustainability and renewable energy solutions.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          Key components of the website include:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Portfolio:</strong> An insightful
          showcase of the company's projects and initiatives, demonstrating its
          impactful contributions to the green energy sector.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Contact Information:</strong> Providing
          users with easily accessible and reliable means to get in touch with
          the company for inquiries, collaboration, or support.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          This website is a testament to the company's dedication to
          environmentally responsible energy solutions, delivering a visually
          engaging and informative online presence.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={ge1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(ge1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={ge2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(ge2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={ge3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(ge3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={ge4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(ge4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={ge5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(ge5);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={ge6}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(ge6);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m2);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={m4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(m4);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default GreenEnergy;
