import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";
import im1 from "../../Assets/whatsapp/1.png";
import im2 from "../../Assets/whatsapp/2.png";
import im3 from "../../Assets/whatsapp/3.png";
import im4 from "../../Assets/whatsapp/4.png";

function WhatsApp() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          Whats<strong className="purple">App </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          This web application has been meticulously developed, utilizing
          state-of-the-art technologies:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Front-End Development:</strong> Employing
          React.js, the web application delivers a highly responsive and
          user-friendly interface, ensuring a seamless and intuitive user
          experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Database and Backend:</strong> Powered by
          Firebase, the application leverages this cloud-based platform for
          robust data management and backend functionalities.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The web application offers a comprehensive range of functionalities
          similar to those found in WhatsApp, excluding video and audio calls:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Instant Messaging:</strong> Users can
          engage in real-time text-based conversations, fostering seamless
          communication.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • User Registration and Authentication:
          </strong>{" "}
          Incorporating secure mechanisms for user registration and identity
          verification.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Group Chats:</strong> Facilitating
          multi-user chat groups, enabling discussions among a community of
          users.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Media Sharing:</strong> Supporting the
          exchange of images, documents, and other files to enhance
          communication.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Notifications:</strong> Keeping users
          informed about incoming messages and updates.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Status Updates:</strong> Allowing users
          to share their current status or mood with their contacts.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Emojis and Stickers:</strong> Enhancing
          communication through the use of expressive and fun visuals.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Contact Management:</strong> Providing
          tools for users to manage and organize their contacts.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Search and Filtering:</strong> Offering
          the ability to search for specific messages and conversations.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Responsive Design:</strong> Ensuring
          compatibility across a variety of devices and screen sizes to provide
          a consistent user experience.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The web application mirrors the core functionalities of a leading
          messaging platform like WhatsApp, excluding video and audio calls,
          while providing a secure and efficient environment for text-based
          communication.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default WhatsApp;
