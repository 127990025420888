import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ImageCard from "./ImageCard";

import im1 from "../../Assets/md/1.jpg";
import im2 from "../../Assets/md/2.jpg";
import im3 from "../../Assets/md/3.jpg";
import im4 from "../../Assets/md/4.jpg";
import im5 from "../../Assets/md/5.jpg";

function MdWebApp() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tempimg, setTempimg] = useState(null);

  const handleThumbnailPress = () => {
    setIsFullScreen(true);
  };

  const handleExitFullScreen = () => {
    setIsFullScreen(false);
    setTempimg(null);
  };
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        {isFullScreen ? (
          <div className="fullScreenContainer" onClick={handleExitFullScreen}>
            <img src={tempimg} className="fullScreenImage" />
          </div>
        ) : null}
        <h1 className="project-heading left">
          MD <strong className="purple">Web App </strong>
        </h1>
        <p style={{ color: "white" }} className="left">
          Application is dedicated to fostering online connections and
          facilitating the journey towards love and, potentially, marriage. It
          features a robust technical foundation:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Utilizes React.js for the front-end, ensuring a modern and
            user-friendly interface.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Employs MySQL for secure and efficient database management.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Leverages PHP for powerful back-end functionality.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">
            • Enables real-time communication through Socket.io, Node.js, and
            Express.js, enhancing user interaction.
          </strong>{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application offers a rich array of functionalities, designed to
          create a seamless and engaging online dating experience:{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Authentication:</strong> Ensures the
          integrity and security of user accounts.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• User Account Management:</strong> Allows
          users to register, log in, log out, edit their profiles, and delete
          their accounts.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Personalization:</strong> Users can add
          their hobbies and interests, as well as upload profile photos and
          create photo galleries.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Profile Browsing:</strong> Offers a
          convenient way to explore and filter profiles to find potential
          matches.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Swipe Mechanism:</strong> Utilizes a
          Tinder-style swiping system for liking or disliking profiles.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Social Network Features:</strong> Users
          can add, remove, or block friends, enhancing their social interaction.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Matching System:</strong> Incorporates a
          likes/dislikes system to facilitate potential matches.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Settings:</strong> Includes age range
          filtering, geolocation filtering, and distance-based filtering to
          personalize user experiences.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Notifications:</strong> Keeps users
          informed with push notifications.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          <strong className="purple">• Payment Integration:</strong> Facilitates
          premium services through a secure and reliable payment system.{" "}
        </p>
        <p style={{ color: "white" }} className="left">
          The application is dedicated to fostering meaningful connections,
          providing users with the tools and features to embark on their journey
          towards love and, ultimately, marriage.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im1}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im1);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im2}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im2);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im3}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im3);
              }}
            />
          </Col>

          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im4}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im4);
              }}
            />
          </Col>
          <Col md={4} className="project-card">
            <ImageCard
              imgPath={im5}
              onSelect={() => {
                handleThumbnailPress();
                setTempimg(im5);
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default MdWebApp;
